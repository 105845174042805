import axios from 'axios';

export default function plugin(API) {
  const container = document.getElementById('members-list');
  const paginationContainer = document.querySelector('.pagination');
  const submitBtn = document.getElementById('submit');
  const clearBtn = document.getElementById('clear');

  const renderMembers = (data) => {
    const {
      member_id,
      firstname,
      lastname,
      street,
      zip,
      city,
      company,
      phone,
      email,
      website,
      branches,
    } = data;

    const template = `
    <div class="item--member model--member">
      <div class="accordion">
        <input type="radio" id="accordion-${member_id}" name="accordion-radio" hidden>
        <label class="accordion-header" for="accordion-${member_id}">
          <div class="model--member__row columns">
            <div class="column col-4 text-bold">
            ${lastname} ${firstname}
            </div>
            <div class="column col-5">
            ${company}
            </div>
            <div class="column col-3">
            ${zip}
            </div>
          </div>
          <span class="arrow arrow-left"></span>
        </label>
        <div class="accordion-body model--member__information">
          <div class="columns">
            <div class="column col-12">
            <span class="text-uppercase text-bold d-block">Branche</span>
              ${branches.toString()}
            </div>
          </div>
          <div class="columns">
            <div class="column col-3 col-sm-12 align-middle">
              <div class="member-info align-middle">
              <svg class="icon icon-map"><use xlink:href="/site/templates/assets/svg/symbols/symbol-defs.svg#icon-map"></use></svg> <span>${street}<br />
                ${zip} ${city}</span>
              </div>
            </div>
            ${
              phone
                ? `<div class='column col-3 col-sm-12 align-middle'>
              <div class='member-info align-middle'>
              <svg class="icon icon-smartphone"><use xlink:href="/site/templates/assets/svg/symbols/symbol-defs.svg#icon-smartphone"></use></svg> <span>${phone}</span>
              </div>
            </div>`
                : ''
            }
            ${
              email
                ? `
            <div class="column col-3 col-sm-12 align-middle">
              <div class="member-info align-middle">
              <svg class="icon icon-email"><use xlink:href="/site/templates/assets/svg/symbols/symbol-defs.svg#icon-email"></use></svg> <span>${email}</span>
              </div>
            </div>`
                : ''
            }
            ${
              website
                ? `
            <div class="column col-3 col-sm-12 align-middle">
              <div class="member-info align-middle">
              <svg class="icon icon-link"><use xlink:href="/site/templates/assets/svg/symbols/symbol-defs.svg#icon-link"></use></svg> <span><a href="${website.replace(
                'http://',
                'https://'
              )}" target="_blank">${website
                    .replace('http://', '')
                    .replace('https://', '')}</a></span>
              </div>
            </div>`
                : ''
            }

          </div>
        </div>
      </div>
    </div>
    `;

    container.insertAdjacentHTML('beforeend', template);
  };

  const renderNotification = (type, message) => {
    const template = `
    <div class="toast toast-${type} d-block alert my-1">
      <button class="btn btn-clear float-right"></button>
      ${message}
    </div>
    `;
    container.textContent = '';
    container.insertAdjacentHTML('beforeend', template);
  };

  const xhr = (url, data) => {
    axios({
      method: 'post',
      url: url,
      data: data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then(function (response) {
        if (response.data.data.length > 0) {
          container.textContent = '';
          response.data.data.map((data) => {
            renderMembers(data);
          });
        } else {
          renderNotification('success', response.data.message);
        }
      })
      .catch(function (error) {
        //handle error
        renderNotification('error', error);
        console.log(error);
      });
  };

  API.memberFilter = () => {
    return {
      filterMembersForm: (selector) => {
        const url = selector.getAttribute('action');

        submitBtn.addEventListener('click', (event) => {
          event.preventDefault();

          let body = new FormData(selector);
          renderNotification('warning', 'Mitglieder werden geladen...');
          xhr(url, body);
          selector.reset();
          paginationContainer.setAttribute('hidden', true);
        });

        clearBtn.addEventListener('click', (event) => {
          event.preventDefault();

          let body = new FormData();
          body.append('reset', 1);
          renderNotification('warning', 'Mitglieder werden geladen...');
          xhr(url, body);
          selector.reset();
          paginationContainer.removeAttribute('hidden');
        });
      },
      paginationMembers: () => {
        const pagination = paginationContainer.querySelectorAll('.xhr-link');

        pagination.forEach((link) => {
          const url = link.getAttribute('href');

          link.addEventListener('click', (event) => {
            event.preventDefault();
            let currentActive = paginationContainer.querySelectorAll('.active');
            currentActive.forEach((item) => {
              item.classList.remove('active');
            });
            event.target.parentNode.classList.add('active');
            renderNotification('warning', 'Mitglieder werden geladen...');

            axios({
              method: 'get',
              url: url,
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
              },
            })
              .then(function (response) {
                if (response.data.data.length > 0) {
                  container.textContent = '';
                  response.data.data.map((data) => {
                    renderMembers(data);
                  });
                } else {
                  renderNotification('success', response.data.message);
                }
              })
              .catch(function (error) {
                renderNotification('error', error);
                console.log(error);
              });
          });
        });
      },
    };
  };
}
