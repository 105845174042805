/*!
 * SITE main application
 * @copyright Copyright 2019
 *
 * Tapio Löytty, <tapsa@orange-media.fi>
 * Web: www.orange-media.fi
 * Goran Ilic, <ja@ich-mach-das.at>
 * Web: www.ich-mach-das.at
 */

// Core
import { create, boot } from 'core';
import * as utils from 'utils';

import formEventRegistration from 'plugins/formEventRegistration';
import hideNotificationAlerts from 'plugins/hideNotificationAlerts';
import imgLazyLoad from 'plugins/imgLazyLoad';
import initializeLightGallery from 'plugins/initializeLightGallery';
import splideSlider from 'plugins/initializeSplide';
import memberFilter from 'plugins/memberFilter';
import processForm from 'plugins/formSubmitHandler';
import scrollToHash from 'plugins/scrollToHash';
import scrollToTop from 'plugins/scrollToTop';
import stickyElement from 'plugins/stickyElement';
import toggleHamburgerMenu from 'plugins/toggleHamburgerMenu';

// Import Vendor
//import AOS from 'aos';
import 'vendor/svgxuse';

//=============================================================
// Config
//=============================================================

const config = {};

//=============================================================
// Options
//=============================================================

const defaultOptions = {
  rootUrl: './',
};

//=============================================================
// Bind API
//=============================================================

const API = create(config);

API.use(formEventRegistration);
API.use(hideNotificationAlerts);
API.use(imgLazyLoad);
API.use(initializeLightGallery);
API.use(memberFilter);
API.use(processForm);
API.use(scrollToHash);
API.use(scrollToTop);
API.use(splideSlider);
API.use(stickyElement);
API.use(toggleHamburgerMenu);

API.page = document.body.dataset;

API.init = (options) => {
  // Set runtime options
  API.options = Object.assign({}, defaultOptions, options);

  API.tasks.init.run();

  // Init gallery plugin
  API.initializeLightGallery('.items--images');
  // Initialize sticky header
  API.stickyElement();
  //Scroll top top
  API.scrollToTop('#back-to-top');
  // Splide slider
  API.splideSlider('.splide');

  // Toggle visibility in profile form
  const toggleCheckbox = document.querySelector('.profile-form-toggle');
  const profileInvoiceContainer = document.querySelector(
    '.profile-form-invoice'
  );
  const toggleInvoiceContainer = () => {
    toggleCheckbox.checked
      ? (profileInvoiceContainer.hidden = false)
      : (profileInvoiceContainer.hidden = true);
  };

  if (toggleCheckbox && profileInvoiceContainer) {
    toggleCheckbox.addEventListener('click', toggleInvoiceContainer);
  }

  // Handle registration form
  API.formEventRegistration();
  // Handle member filtering
  const filterForm = document.getElementById('form-members-filter');
  if (filterForm) {
    API.memberFilter().filterMembersForm(filterForm);
    API.memberFilter().paginationMembers();
  }

  // Handle form validation and submit
  const forms = document.querySelectorAll('.form-validate');
  forms.forEach((form) => {
    let errorList = form.querySelector('ul.alerts-validate');

    API.processForm(form, errorList).validateBeforeSubmit();

    if (form.classList.contains('form-ajax'))
      API.processForm(form, errorList).submitWithAjax();
  });

  // Init mobile menu icon
  API.toggleHamburgerMenu('.navigation--trigger', '#main-navbar');

  API.tasks.ready.run();

  window.addEventListener('unhandledrejection', function (event) {
    console.warn(
      'WARNING: Unhandled promise rejection. Reason: ' + event.reason,
      event
    );
  });
};

//=============================================================
// Boot API
//=============================================================

boot(API);
