/**
 *
 * @param {*} API
 */

export default function plugin(API) {
  API.stickyElement = () => {
    if (
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    ) {
      let observer = new IntersectionObserver((entries) => {
        if (entries[0].boundingClientRect.y < 0) {
          document.body.classList.add('is-navbar-sticky');
        } else {
          document.body.classList.remove('is-navbar-sticky');
        }
      });
      observer.observe(document.querySelector('#top-anchor'));
    }
  };
}
