import Splide from '@splidejs/splide';

export default function plugin(API) {
  API.splideSlider = (selector) => {
    document.addEventListener('DOMContentLoaded', function () {
      let options = {
        type: 'loop',
        padding: '2rem',
        gap: '2rem',
        perPage: 2,
        fixedWidth: '50%',
        breakpoints: {
          940: {
            perPage: 1,
            fixedWidth: '100%',
          },
        },
      };

      if (document.querySelector(selector)) {
        const splide = new Splide(selector, options);

        splide.mount();
      }
    });
  };
}
