import scrollToHash from 'plugins/scrollToHash';

export default function plugin(API) {
  API.toggleHamburgerMenu = (trigger, navigation) => {
    let hamburger = {
      openElement: document.querySelector(trigger),
      navigationElement: document.querySelector(navigation),
      pageElement: document.querySelector('html'),
    };

    const navbarItems = document.querySelectorAll(
      navigation + ' .navbar-item > a'
    );

    const doToggle = (e) => {
      e.preventDefault();
      hamburger.openElement.classList.toggle('is-open');
      hamburger.navigationElement.classList.toggle('is-open');
      hamburger.pageElement.classList.toggle('is-open');
    };

    navbarItems.forEach((item) => {
      item.addEventListener('click', (event) => {
        let hash = event.target.hash;

        if (hash && document.querySelector(hash)) {
          event.preventDefault();
          API.scrollToHash(hash);
        }
        //if (event.target.href === )
        if (hamburger.pageElement.classList.contains('is-open')) {
          hamburger.openElement.classList.toggle('is-open');
          hamburger.navigationElement.classList.toggle('is-open');
          hamburger.pageElement.classList.toggle('is-open');
        }
      });
    });

    hamburger.openElement.addEventListener('click', (e) => {
      doToggle(e);
    });
  };
}
