export default function plugin(API) {
  API.formEventRegistration = () => {
    const elements = {
      optionStatus: document.getElementById('status-select'),
      optionAccompany: document.getElementById('toggle-accompany'),
      memberContainer: document.getElementById('member-container'),
      substituteContainer: document.getElementById('substitute-container'),
      substituteFields: document.getElementById('substitute-fields'),
      accompanyContainer: document.getElementById('accompany-container'),
      accompanyFields: document.getElementById('accompany-fields'),
      addBtn: document.getElementById('add-btn'),
      removeBtn: document.getElementById('remove-btn'),
    };

    const renderInputs = (element, type, index = 0) => {
      const markup = `
      <li>
        <input type="hidden" name="${type}[${index}][type]" value="${type}" />
        <div class="form-group columns">
          <div class="column col-6 col-sm-12">
            <label class="form-label" for="salutation_${type}_${index}">Anrede</label>
            <select class="form-select" name="${type}[${index}][salutation]" id="salutation_${type}_${index}" required>
              <option value="">Bitte auswählen</option>
              <option value="Frau">Frau</option>
              <option value="Herr">Herr</option>
            </select>
          </div>
        </div>
        <div class="form-group columns">
          <div class="column col-6 col-sm-12">
            <label class="form-label" for="firstname_${type}_${index}">Vorname</label>
            <input class="form-input" type="text" name="${type}[${index}][firstname]" id="firstname_${type}_${index}" placeholder="Vorname" value="" required />
          </div>
          <div class="column col-6 col-sm-12">
            <label class="form-label" for="lastname_${type}_${index}">Nachname</label>
            <input class="form-input" type="text" name="${type}[${index}][lastname]" id="lastname_${type}_${index}" placeholder="Nachname" value="" required />
          </div>
          <div class="column col-6 col-sm-12">
            <label class="form-label" for="company_${type}_${index}">Firma</label>
            <input class="form-input" type="text" name="${type}[${index}][company]" id="company_${type}_${index}" placeholder="Firma" value="" />
          </div>
          <div class="column col-6 col-sm-12">
            <label class="form-label" for="email_${type}_${index}">E-Mail</label>
            <input class="form-input" type="email" name="${type}[${index}][application_email]" id="email_${type}_${index}" placeholder="E-Mail" value="" />
          </div>
        </div>
      </li>
      `;

      element.insertAdjacentHTML('beforeend', markup);
    };

    if (elements.optionStatus && elements.optionStatus.value === '2') {
      elements.substituteContainer.hidden = false;
      elements.memberContainer.hidden = true;
    }

    if (elements.optionAccompany && elements.optionAccompany.checked) {
      elements.accompanyContainer.hidden = false;
    }

    // Event Listeners
    if (elements.optionStatus) {
      elements.optionStatus.addEventListener('change', (e) => {
        if (e.target.value === '2') {
          elements.substituteContainer.hidden = false;
          elements.memberContainer.hidden = true;
          renderInputs(elements.substituteFields, 'vertretung');
        } else {
          elements.substituteContainer.hidden = true;
          elements.memberContainer.hidden = false;
          elements.substituteFields.textContent = '';
        }
      });
    }

    if (elements.optionAccompany) {
      elements.optionAccompany.addEventListener('change', (e) => {
        if (e.target.checked) {
          elements.accompanyContainer.hidden = false;
          renderInputs(elements.accompanyFields, 'begleitung');
        } else {
          elements.accompanyContainer.hidden = true;
          elements.accompanyFields.textContent = '';
        }
      });
    }

    if (elements.addBtn) {
      elements.addBtn.addEventListener('click', (e) => {
        e.preventDefault();
        let index = elements.accompanyFields.getElementsByTagName('li').length;
        renderInputs(elements.accompanyFields, 'begleitung', index);
      });
    }

    if (elements.removeBtn) {
      elements.removeBtn.addEventListener('click', (e) => {
        e.preventDefault();
        if (elements.accompanyFields.lastElementChild) {
          elements.accompanyFields.removeChild(
            elements.accompanyFields.lastElementChild
          );
        }
      });
    }
  };
}
